.select[data-v-43c48d96] {
  padding-top: 1rem;
}
.select .banner[data-v-43c48d96] {
  height: 3.5rem;
  width: 100%;
  position: relative;
  background: url(../../static/img/bg.d0ba7ced.jpg) no-repeat center center / cover;
}
.select .banner .selectbox[data-v-43c48d96] {
  z-index: 10;
  font-size: 0.18rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 640rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 0.7rem;
  vertical-align: middle;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.select .banner .selectbox[data-v-43c48d96] .input {
  display: inline-block;
  position: unset;
  width: 4.7rem;
  height: 100%;
}
.select .banner .selectbox[data-v-43c48d96] .input .el-input__wrapper {
  width: calc(100% - 0.4rem);
  padding: 0 0.2rem;
  height: 100%;
  border-radius: 0.1rem 0 0 0.1rem;
}
.select .banner .selectbox[data-v-43c48d96] .input .el-input__wrapper.is-focus {
  -webkit-box-shadow: 0 0 0 0.01rem var(--themeColor) inset;
  box-shadow: 0 0 0 0.01rem var(--themeColor) inset;
}
.select .banner .btn[data-v-43c48d96] {
  display: inline-block;
  background: #13c4ab;
  border-radius: 0 0.1rem 0.1rem 0;
  padding: 0 0.48rem;
  color: #ffffff;
  height: 100%;
  line-height: 0.7rem;
  cursor: pointer;
}
.select .banner .btn[data-v-43c48d96]:hover {
  background: var(--themeColor);
}
.select .banner[data-v-43c48d96]::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 144, 147, 0.7);
  position: absolute;
  top: 0;
  left: 0;
}
.select .container[data-v-43c48d96] {
  margin: 1.2rem auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.select .container[data-v-43c48d96] .container-left .el-radio-group {
  margin-right: 0.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.select .container[data-v-43c48d96] .container-left .el-radio-group .el-radio.el-radio--large .el-radio__label {
  color: #000000;
  font-size: var(--f18);
  display: block;
  width: 100%;
}
.select .container[data-v-43c48d96] .container-left .el-radio-group .el-radio__input.is-checked .el-radio__inner {
  border-color: var(--comthemeColor);
  background: var(--comthemeColor);
}
.select .container[data-v-43c48d96] .container-left .el-radio-group .el-radio__input.is-checked + .el-radio__label {
  color: var(--comthemeColor);
}
.select .container .container-right[data-v-43c48d96] {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.select .container .container-right .nodata[data-v-43c48d96] {
  font-size: var(--f24);
  text-align: center;
}
.select .container .container-right-list-item[data-v-43c48d96] {
  margin-bottom: 0.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.5rem;
  cursor: pointer;
  background-color: #f4f5f5;
}
.select .container .container-right-list-item-time[data-v-43c48d96] {
  color: #666666;
  font-size: var(--f24);
  margin-right: 0.85rem;
}
.select .container .container-right-list-item-box[data-v-43c48d96] {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.select .container .container-right-list-item-box .title[data-v-43c48d96] {
  color: #000000;
  font-size: var(--f24);
}
.select .container .container-right-list-item-box .more[data-v-43c48d96] {
  margin-top: 0.4rem;
  font-size: var(--f16);
  color: var(--themeColor);
}
.select .container .container-right-list-item-box .more .icon[data-v-43c48d96] {
  color: var(--themeColor);
}
.select .container .container-right-list-item[data-v-43c48d96]:hover {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.08);
  border-bottom: 0.02rem solid var(--themeColor);
}
.select .container .container-right-list-item:hover .announce-right-list-item-box .title[data-v-43c48d96] {
  color: var(--themeColor) !important;
}
.select .container[data-v-43c48d96] .el-pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.select .container[data-v-43c48d96] .el-pagination .el-pager li {
  border-radius: 50%;
}
.select .container[data-v-43c48d96] .el-pagination .el-pager li:not(.is-disabled).is-active {
  background-color: var(--themeColor);
}
.select .container[data-v-43c48d96] .el-pagination .el-pager li:not(.is-disabled):hover {
  color: var(--themeColor);
}