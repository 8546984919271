.content[data-v-02ef2df1] {
  padding-top: 1rem;
}
.content .box[data-v-02ef2df1] {
  width: 15rem;
  margin: 0 auto;
  position: relative;
}
.content .box .box-text[data-v-02ef2df1] {
  width: 50%;
  margin: 0.5rem auto;
}
.content .box .box_icon[data-v-02ef2df1] {
  position: absolute;
  right: 2.5rem;
  top: 1.4rem;
}
.content .box .box_icon .sharecontent .sharelist[data-v-02ef2df1] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.content .box .box_icon .sharecontent .sharelist .fenxiang[data-v-02ef2df1] {
  font-size: 0.14rem;
}
.content .box .box_icon .sharecontent .sharelist .sharelist-item[data-v-02ef2df1] {
  margin: 0.1rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.content .box .box_icon .sharecontent .sharelist .sharelist-item img[data-v-02ef2df1] {
  width: 0.26rem;
  height: 0.26rem;
  padding: 0.12rem 0.12rem;
  border: 0.02rem solid #ebebeb;
  background-color: #ffffff;
}
.content .box .box_icon .sharecontent .sharelist .sharelist-item:hover img[data-v-02ef2df1] {
  background-color: var(--themeColor);
}
.content .box .box_icon .sharecontent .return[data-v-02ef2df1] {
  color: #999999;
  font-size: var(--f14);
  width: 1.04rem;
  height: 0.54rem;
  border: 0.02rem solid #ededed;
  text-align: center;
  line-height: 0.54rem;
  cursor: pointer;
}
.content .title[data-v-02ef2df1] {
  font-size: 0.38rem;
  color: #002060;
  line-height: 0.4rem;
  font-weight: 700;
}
.content .time[data-v-02ef2df1] {
  font-size: 0.16rem;
  color: #666;
  line-height: 0.25rem;
  margin-bottom: 0.34rem;
  letter-spacing: 0.008rem;
}
.content .text[data-v-02ef2df1] {
  font-size: 0.18rem !important;
  line-height: 0.32rem !important;
  margin: 0.2rem 0 !important;
}